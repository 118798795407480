import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Divider } from "@mui/material";
import PageLayout from "../../components/Layout/PageLayout";
import Radio from "../../components/Inputs/Radio";
import PathConstants from "../../routes/pathConstants";

import { useRate } from "../../hooks/rates";
import { useSelectedRate } from "../../hooks/rate-select";
import ArrowForward from "../../assets/Icons/ArrowForward";
import ArrowBack from "../../assets/Icons/ArrowBack";
import { postPay } from "../../services/pay";
import ErrorModal from "../../components/Layout/ErrorModal";
import Modal from "../../components/Layout/Modal";
import cacheKeys from "../../constants/cache";
import Alert from "../../assets/Icons/Alert";

const ParkingRates = () => {
  const rateFailedDescription =
    "An error occurred retrieving available rates. Please try again or contact support if the problem persists.";

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [rateError, setRateError] = useState(null);
  const [rateMessage, setRateMessage] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);

  const onSuccessRapidCheckout = async () => {
    setIsPaying(true);
    const date = new Date();
    const terms = date.toISOString();

    const paymentObject = {
      elavonResponseData: null,
      termsAndConditionsOK: terms,
      sendTextReceipt: !contactInfo.showEmail,
      sendEmailReceipt: contactInfo.showEmail,
      phoneNumber: contactInfo.phone,
      email: contactInfo.email,
    };

    try {
      const payRes = await postPay(paymentObject);
      queryClient.setQueryData(["pay"], payRes);
      setIsPaying(false);
      navigate(PathConstants.CHECKOUT_COMPLETE);
    } catch (error) {
      setRateError("An unknown error occurred, please try again.");
      setIsPaying(false);
    }
  };

  const {
    data: rateData,
    isFetching,
    error: rateCallError,
  } = useRate(onSuccessRapidCheckout);
  const { data: selectedRate } = useSelectedRate();

  const contactInfo = queryClient.getQueryData(["contactInfo"]);
  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);

  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if (!contactInfo) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, contactInfo]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleOnClick = (path) => {
    const rate = rateData.rates.find(
      (r) => r.rateID === selectedRate.selectedRateId
    );
    if (rate.warningMessage) {
      setWarningMessage(rate.warningMessage);
      setShowWarning(true);
    } else handleNavigate(path);
  };

  const handleOnClickBack = () => {
    navigate(-1);
  };

  const handleOnRateSelect = useCallback(
    (rateId) => {
      queryClient.setQueryData(["rate-select"], {
        selectedRateId: rateId,
      });
    },
    [queryClient]
  );

  useEffect(() => {
    if (rateData?.rates.length === 0) {
      setRateMessage(rateData.noRatesMessage);
    }
    if (rateData?.success === false) {
      setRateError(rateFailedDescription);
    }
    if (
      !isFetching &&
      rateData?.success &&
      rateData?.rates.length === 1 &&
      rateData?.useRapidCheckout === false
    ) {
      handleOnRateSelect(rateData.rates[0].rateID);
      navigate(PathConstants.CHECKOUT, { replace: true });
    }
  }, [handleOnRateSelect, navigate, rateData, isFetching]);

  return isRedirecting ? null : (
    <PageLayout
      title="Choose Rate"
      withCancel
      isLoading={isFetching || isPaying}
    >
      <ErrorModal
        open={!!rateError || !!rateCallError}
        onClose={() => {
          setRateError(null);
          navigate(-1);
        }}
        description={rateError ?? rateFailedDescription}
      />
      <Modal
        open={!!rateMessage && !rateError}
        onClose={() => setRateMessage(null)}
      >
        <div>
          <span className="mx-auto">
            <div>
              {rateMessage && (
                <div className="text-center mt-4">{rateMessage}</div>
              )}
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div>
                <div className="mt-4 flex justify-center">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => setRateMessage(null)}
                    color="primary"
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      width: "45%",
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
      <Modal
        open={showWarning}
        onClose={() => {
          setShowWarning(false);
          setWarningMessage(null);
        }}
      >
        <div>
          <span className="mx-auto">
            <Alert />
            <div>
              <div className="text-center mt-4 font-bold text-lg">Warning</div>
              <div className="text-center mt-4">{warningMessage}</div>
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div>
                <div className="mt-4 flex flex-wrap">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      setShowWarning(false);
                      setWarningMessage(null);
                    }}
                    color="secondary"
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      flexGrow: 1,
                      margin: ".25rem",
                      minWidth: "160px",
                    }}
                  >
                    Take Me Back!
                  </Button>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      handleNavigate(PathConstants.CHECKOUT);
                    }}
                    color="error"
                    style={{
                      fontWeight: 700,
                      flexGrow: 1,
                      margin: ".25rem",
                      height: "2.5rem",
                      minWidth: "160px",
                    }}
                    disabled={false}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
      {rateData?.rates?.map((rate) => {
        return (
          <Radio
            key={rate.rateID}
            label={rate.rateName}
            amount={`$${rate.rateAmount.toFixed(2).replace(/\.00$/, "")}`}
            value={rate.rateID}
            onChange={() => handleOnRateSelect(rate.rateID)}
            checked={rate.rateID === selectedRate.selectedRateId}
          ></Radio>
        );
      })}
      <div className="mt-4">
        <Button
          size="large"
          variant="contained"
          onClick={() => handleOnClickBack()}
          color="secondary"
          style={{
            fontWeight: 700,
            width: "30%",
            height: "3rem",
            position: "inline-block",
            marginRight: "1rem",
          }}
          startIcon={<ArrowBack fill="var(--neutral-white-color)" />}
        >
          Back
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleOnClick(PathConstants.CHECKOUT)}
          color="primary"
          style={{ fontWeight: 700, width: "65%", height: "3rem" }}
          endIcon={
            <ArrowForward
              fill={
                selectedRate.selectedRateId === ""
                  ? "rgba(0, 0, 0, 0.26)"
                  : "var(--secondary-color)"
              }
            />
          }
          disabled={selectedRate.selectedRateId === ""}
        >
          Checkout
        </Button>
      </div>
    </PageLayout>
  );
};

export default ParkingRates;
