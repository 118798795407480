import { Button, Divider } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import ArrowBack from "../../assets/Icons/ArrowBack";
import ArrowForward from "../../assets/Icons/ArrowForward";
import CreditCardImages from "../../components/Misc/CreditCardImages/CreditCardImages";
import ElavonErrorModal from "../../components/Layout/ElavonErrorModal";
import ErrorModal from "../../components/Layout/ErrorModal";
import GooglePayButton from "@google-pay/button-react";
import PageLayout from "../../components/Layout/PageLayout";
import PathConstants from "../../routes/pathConstants";
import TextField from "../../components/Inputs/TextField";
import cacheKeys from "../../constants/cache";
import { errorLog } from "../../services/errorLog";
import { normalizeCardNumber } from "../../utils/formatCardNumber";
import { normalizeExpiration } from "../../utils/formatExpiration";
import { normalizeSecurityCode } from "../../utils/formatSecurityCode";
import { normalizeZipCode } from "../../utils/formatZipCode";
import { postElavonAppleRequest } from "../../services/elavonApple";
import { postElavonGoogleConfigure } from "../../services/elavonGoogleConfigure";
import { postElavonGooglePay } from "../../services/elavonGooglePay";
import { postPay } from "../../services/pay";
import { useContactInfo } from "../../hooks/contact-info";
import { useNavigate } from "react-router-dom";
import { usePaymentIntent } from "../../hooks/payment-intent";
import { useQueryClient } from "@tanstack/react-query";

const Payment = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [cardholderName, setCardholderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipCodeWarning, setZipCodeWarning] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [payIsLoading, setPayIsLoading] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState("");
  const [errorFields, setErrorFields] = useState({});
  const [paymentIntentError, setPaymentIntentError] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [eWalletConfig, setEWalletConfig] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [walletIsLoading, setWalletIsLoading] = useState(false);
  const [walletError, setWalletError] = useState(null);

  const data = queryClient.getQueryData(["checkout"]);
  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);
  const googlePayEnvironment = import.meta.env.VITE_GOOGLE_PAY_ENV ?? "TEST";

  //Check for existing state before rendering component
  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if (!data) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, data]);

  useEffect(() => {
    if (zipCode === "") return;
    const isValid =
      /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(zipCode) ||
      /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/.test(zipCode);
    setZipCodeWarning(isValid ? "" : "Please enter a valid zip code.");
  }, [zipCode]);

  const { transactionDetails } = data ?? { transactionDetails: null };

  const { data: contactInfo } = useContactInfo();

  const dateOnly = new Date().toISOString().split("T")[0];
  const cacheKey = `${contactInfo?.licensePlate}#${dateOnly}#${transactionDetails?.amountDue}`;

  const callback = useMemo(
    () => ({
      onError: async (error) => {
        try {
          await errorLog(
            `[Payment Processor: Elavon] [Payment Method: ${paymentMethod}] Error: ${error}`
          );
        } catch (e) {
          console.error("Unable to log error on API", e);
        }
        setShowPaymentError(true);
        setPayIsLoading(false);
      },
      onDeclined: async (response) => {
        setErrorFields(response);
        try {
          const errorMessage = transformElavonError(response);
          const responseFields = JSON.stringify(response);
          await errorLog(
            `[Payment Processor: Elavon] [Payment Method: ${paymentMethod}] Error: ${errorMessage} response fields: ${responseFields}`
          );
        } catch (e) {
          console.error("Unable to log error on API", e);
        }
        setShowPaymentError(true);
        setPayIsLoading(false);
      },
      onApproval: async (response) => {
        window.sessionStorage.setItem(cacheKey, JSON.stringify(response));
        const date = new Date();
        const terms = date.toISOString();
        const paymentObject = {
          elavonResponseData: response,
          termsAndConditionsOK: terms,
          sendTextReceipt: !contactInfo.showEmail,
          sendEmailReceipt: contactInfo.showEmail,
          phoneNumber: contactInfo.phone,
          email: contactInfo.email,
          paymentProcessor: "Elavon",
        };
        try {
          const payRes = await postPay(paymentObject);
          queryClient.setQueryData(["pay"], payRes);
          window.sessionStorage.removeItem(cacheKey);
          navigate(PathConstants.CHECKOUT_COMPLETE);
        } catch (error) {
          setPaymentIntentError("unknownSystemError");
        } finally {
          setPayIsLoading(false);
        }
      },
      onCancelled: function () {
        setPayIsLoading(false);
      },
    }),
    [
      cacheKey,
      contactInfo.email,
      contactInfo.phone,
      contactInfo.showEmail,
      navigate,
      queryClient,
      paymentMethod,
    ]
  );

  const configureEWallet = async (paymentIntent) => {
    setWalletIsLoading(true);
    try {
      const jsonString = localStorage.getItem("convergeContext");
      const cacheObject = jsonString
        ? JSON.parse(jsonString)
        : {
            list: {},
            latestId: paymentIntent.elavonRequestData.token,
          };
      // If this token was already cached then don't try to create a new one
      if (cacheObject.latestId === paymentIntent.elavonRequestData.token) {
        const latestConfig =
          cacheObject.list[paymentIntent.elavonRequestData.token];
        if (latestConfig) {
          setEWalletConfig(latestConfig.response);
          setWalletIsLoading(false);
          return;
        }
      }
      // If not cached go get it for the proper e-wallet
      var configObject = {
        fields: { ssl_txn_auth_token: paymentIntent.elavonRequestData.token },
      };
      const configRes =
        window.ApplePaySession && window.ApplePaySession.canMakePayments
          ? await postElavonAppleRequest("ewallet/apresource", configObject)
          : await postElavonGoogleConfigure(configObject);
      console.log(configRes.error);
      if (configRes.error) throw new Error(configRes.errorMessage);
      // Store the response in a cache for Elavon to use
      cacheObject.list[paymentIntent.elavonRequestData.token] = {
        content: configRes.context,
        response: configRes,
        timestamp: new Date().getTime(),
      };
      cacheObject.latestId = paymentIntent.elavonRequestData.token;
      localStorage.setItem("convergeContext", JSON.stringify(cacheObject));
      setEWalletConfig(configRes);
      setWalletIsLoading(false);
    } catch (e) {
      try {
        await errorLog(
          `[Payment Processor: Elavon] Loading Wallet Error: ${e}`
        );
      } catch (ex) {
        console.error("Unable to log error on API", ex);
      }
      setWalletError(e);
      setWalletIsLoading(false);
    }
  };

  const {
    data: paymentIntent,
    isFetching,
    error: pageLoadError,
  } = usePaymentIntent("Elavon", configureEWallet, setPaymentIntentError);

  if (!data || !qrCodeCache || isRedirecting) {
    return null; // Early return to prevent rendering when data is undefined or redirecting
  }

  const handleOnClickBack = () => {
    navigate(-1);
  };

  const handleOnChangeCardNumber = (value) => {
    setCardNumber(normalizeCardNumber(value));
  };

  const handleOnChangeSecurityCode = (value) => {
    setSecurityCode(normalizeSecurityCode(value));
  };

  const handleOnChangeExpirationDate = (value) => {
    setExpirationDate(normalizeExpiration(value));
  };

  const handleOnChangeZipCode = (value) => {
    setZipCode(normalizeZipCode(value));
  };

  const resendPayment = () => {
    const cachedReponse = window.sessionStorage.getItem(cacheKey);
    if (cachedReponse) {
      const data = JSON.parse(cachedReponse);
      callback.onApproval(data);
      return true;
    }
    return false;
  };

  const pay = () => {
    setPayIsLoading(true);
    setPaymentMethod("Credit Card");
    if (resendPayment()) return;
    var paymentData = {
      ssl_txn_auth_token: paymentIntent.elavonRequestData.token,
      ssl_card_number: cardNumber,
      ssl_exp_date: expirationDate,
      ssl_name: cardholderName,
      ssl_cvv2cvc2: securityCode,
      ssl_merchant_txn_id: paymentIntent.elavonRequestData.ssl_merchant_txn_id,
      ssl_avs_zip: zipCode,
    };
    // Exists at runtime in https://api.convergepay.com/hosted-payments/Checkout.js
    // eslint-disable-next-line no-undef
    ConvergeEmbeddedPayment.pay(paymentData, callback);
    return false;
  };

  const callElavonApple = async (url, fields) => {
    const body = {
      context: eWalletConfig.context,
      fields: fields,
    };
    return await postElavonAppleRequest(url, body);
  };

  const buildAppleAddress = (addressLines) => {
    let output = "";
    if (addressLines) {
      try {
        for (var n = 0; n < addressLines.length; n++) {
          output =
            output.length > 0
              ? output + " " + addressLines[n]
              : addressLines[n];
        }
      } catch (error) {
        console.log(error);
      }
    }
    return output;
  };

  const buildApplePayPayment = (payment) => {
    const shipping = payment.shippingContact;
    const billing = payment.billingContact;
    const output = {};
    if (billing) {
      output.ssl_first_name = billing.givenName;
      output.ssl_last_name = billing.familyName;
      output.ssl_city = billing.locality;
      output.ssl_state = billing.administrativeArea;
      output.ssl_country = billing.countryCode;
      output.ssl_avs_address = buildAppleAddress(billing.addressLines);
      if (billing.postalCode) {
        output.ssl_avs_zip =
          billing.postalCode.toString().length > 9
            ? billing.postalCode.toString().replace(/[^A-Za-z0-9]/g, "")
            : billing.postalCode;
      }
    }
    if (shipping) {
      output.ssl_ship_to_first_name = shipping.givenName;
      output.ssl_ship_to_last_name = shipping.familyName;
      output.ssl_ship_to_address1 = buildAppleAddress(shipping.addressLines);
      output.ssl_ship_to_city = shipping.localit;
      output.ssl_ship_to_country = shipping.countryCode;
      output.ssl_ship_to_phone = shipping.phoneNumber;
      output.ssl_email = shipping.emailAddress;
      output.ssl_ship_to_state = shipping.administrativeArea;
      if (shipping.postalCode) {
        output.ssl_ship_to_zip =
          shipping.postalCode.toString().length > 10
            ? shipping.postalCode.toString().replace(/[^A-Za-z0-9]/g, "")
            : shipping.postalCode;
      }
    }
    output.ssl_applepay_web = JSON.stringify(payment.token.paymentData);
    return output;
  };

  const applePay = async () => {
    if (eWalletConfig === null) return;
    setPayIsLoading(true);
    setPaymentMethod("Apple Pay");
    try {
      if (resendPayment()) return;
      var country = "CAN" === eWalletConfig.country ? "CA" : "US";
      var appleConfig = {
        countryCode: country,
        currencyCode: eWalletConfig.currency,
        total: {
          label: eWalletConfig.merchantName,
          amount: eWalletConfig.amount,
        },
        requiredBillingContactFields: ["name", "postalAddress"],
        requiredShippingContactFields: eWalletConfig.appleShippingFields,
        supportedNetworks: ["amex", "discover", "masterCard", "visa"],
        merchantCapabilities: ["supports3DS"],
      };
      // Exists at runtime within Safari Browser
      // eslint-disable-next-line no-undef
      var apSession = new ApplePaySession(1, appleConfig);
      apSession.onvalidatemerchant = async (appleContext) => {
        var merchValidationConfig = {
          applepayUrl: appleContext.validationURL,
          applepayDomain: window.location.hostname,
        };
        const validationResponse = await callElavonApple(
          "ewallet/apvalidation",
          merchValidationConfig
        );
        if (validationResponse.fields.applepaySession) {
          try {
            apSession.completeMerchantValidation(
              JSON.parse(validationResponse.fields.applepaySession)
            );
          } catch (e) {
            callback.onError(
              `ApplePay session error: ${JSON.stringify(e)} for session: ${
                validationResponse.fields.applepaySession
              }`
            );
          }
        } else {
          callback.onError("ApplePay session error");
        }
      };
      apSession.onpaymentmethodselected = () => {
        const total = {
          type: "final",
          label: eWalletConfig.merchantName,
          amount: eWalletConfig.amount,
        };
        const lineItems = [
          {
            type: "final",
            label: eWalletConfig.merchantName,
            amount: eWalletConfig.amount,
          },
        ];
        apSession.completePaymentMethodSelection(total, lineItems);
      };
      apSession.onpaymentauthorized = async (appleContext) => {
        var applePayment = buildApplePayPayment(appleContext.payment);
        const paymentResponse = await callElavonApple(
          "ewallet/appayment",
          applePayment
        );
        if (paymentResponse.error) {
          let safePaymentResponse;

          try {
            safePaymentResponse = JSON.stringify(paymentResponse);
          } catch (error) {
            // Handle the case where paymentResponse cannot be stringified
            safePaymentResponse = `Could not stringify payment response: ${paymentResponse}`;
          }

          callback.onError(paymentResponse.errorMessage || safePaymentResponse);
          // eslint-disable-next-line no-undef
          apSession.completePayment(ApplePaySession?.STATUS_FAILURE);
        } else if (paymentResponse.approved) {
          callback.onApproval(
            paymentResponse.fields,
            paymentResponse.hashValue
          );
          // eslint-disable-next-line no-undef
          apSession.completePayment(ApplePaySession?.STATUS_SUCCESS);
        } else {
          callback.onDeclined(
            paymentResponse.fields,
            paymentResponse.hashValue
          );
          // eslint-disable-next-line no-undef
          apSession.completePayment(ApplePaySession?.STATUS_FAILURE);
        }
      };
      apSession.oncancel = () => {
        callback.onCancelled();
      };
      apSession.begin();
    } catch (error) {
      try {
        await errorLog(
          `[Payment Processor: Elavon] [Payment Method: ${paymentMethod}] Error: ${error}`
        );
      } catch (e) {
        console.error("Unable to log error on API", e);
      }
      setShowPaymentError(true);
      setPayIsLoading(false);
    }
    return false;
  };

  const googlePay = async (googleData) => {
    if (eWalletConfig === null) return;
    setPayIsLoading(true);
    setPaymentMethod("Google Pay");
    try {
      if (resendPayment()) return;
      const paymentObject = {
        context: eWalletConfig.context,
        fields: {
          rawPaymentData: JSON.stringify(googleData),
          gatewayMerchantId: eWalletConfig.terminalNumber,
        },
      };
      const payRes = await postElavonGooglePay(paymentObject);
      if (payRes.error) {
        let safePayRes;

        try {
          safePayRes = JSON.stringify(payRes);
        } catch (error) {
          // Handle the case where payRes cannot be stringified
          safePayRes = `Could not stringify payment response: ${payRes}`;
        }

        callback.onError(payRes.errorMessage || safePayRes);
      } else if (payRes.approved) {
        callback.onApproval(payRes.fields);
      } else {
        callback.onDeclined(payRes.fields);
      }
    } catch (error) {
      try {
        await errorLog(
          `[Payment Processor: Elavon] [Payment Method: ${paymentMethod}] Error: ${error}`
        );
      } catch (e) {
        console.error("Unable to log error on API", e);
      }
      setShowPaymentError(true);
      setPayIsLoading(false);
    }
    return false;
  };

  const paymentInfoValid =
    cardholderName?.length > 0 &&
    cardNumber?.length >= 18 &&
    cardNumber?.length <= 19 &&
    expirationDate?.length === 7 &&
    securityCode?.length >= 3 &&
    securityCode?.length <= 4 &&
    zipCode?.length > 0 &&
    zipCodeWarning?.length === 0;

  const transformElavonError = (errorObject) => {
    //Messaging for unknown errorCode and ssl_issuer_response
    const default_error_message =
      "There was a problem processing your transaction. Please try again. If the problem persists, try a different payment method or find an attendant.";

    //Messaging used by multiple ssl_issuer_response codes
    const default_decline_message =
      "Your transaction was declined. Please contact your bank or card issuer for more information.";

    if (errorObject?.errorCode) {
      let errorMessage = "";
      switch (errorObject.errorCode) {
        case "5000":
          errorMessage =
            "The Credit Card Number supplied appears to be invalid. Please try again.";
          break;
        case "5001":
          errorMessage =
            "The Expiration Date supplied appears to be invalid. Please try again.";
          break;
        case "5021":
          errorMessage =
            "The Security Code supplied appears to be invalid. Please try again.";
          break;
        default:
          errorMessage = default_error_message;
          break;
      }
      return `${errorMessage} (Code ${errorObject.errorCode})`;
    } else if (errorObject?.ssl_issuer_response) {
      let declineMessage = "";
      switch (errorObject.ssl_issuer_response) {
        case "N7": // Decline for CVV2 failure
          declineMessage =
            "The Security Code supplied appears to be invalid. Please try again.";
          break;
        case "1": // Contact bank
          declineMessage = default_decline_message;
          break;
        case "5": // Do not honor
          declineMessage = default_decline_message;
          break;
        case "14": // Invalid card number
          declineMessage =
            "The Credit Card Number supplied appears to be invalid. Please try again.";
          break;
        case "19": // Retry error
          declineMessage =
            "There was a problem processing your transaction. Please try again. If the issue persists, contact your bank or try a different payment method.";
          break;
        case "30": // Format error
          declineMessage =
            "There was an issue with the information provided for your transaction. Please double-check your details and try again.";
          break;
        case "41": // Lost card
          declineMessage =
            "This transaction was declined because the card has been reported lost. Please contact your bank for assistance or use a different payment method.";
          break;
        case "43": //Stolen card
          declineMessage = default_decline_message;
          break;
        case "46": // Closed account
          declineMessage = default_decline_message;
          break;
        case "51": // Insufficient funds
          declineMessage = default_decline_message;
          break;
        case "54": // Expired card
          declineMessage =
            "Your transaction was declined because the card has expired. Please use a different card or contact your bank for assistance.";
          break;
        case "59": // Suspected fraud
          declineMessage = default_decline_message;
          break;
        case "78": // Blocked, first use
          declineMessage = default_decline_message;
          break;
        case "82": // Invalid CVV/CAM
          declineMessage =
            "The Security Code or Expiration Date supplied appears to be invalid. Please try again.";
          break;
        case "91": // Issuer unavailable
          declineMessage =
            "Unable to reach your card issuer or bank. Please try again later or use a different payment method.";
          break;
        case "93": // Transaction cannot be completed - violation of law
          declineMessage = default_decline_message;
          break;
        default:
          //Use default error message for unmapped response codes
          declineMessage = default_error_message;
          break;
      }
      return `${declineMessage} (Code ${errorObject.ssl_issuer_response})`;
    } else return `${default_error_message} (Unknown Code)`;
  };

  //Do not render anything if we are redirecting
  return isRedirecting ? null : (
    <PageLayout
      title="Enter Payment Information"
      withCancel
      isLoading={payIsLoading || isFetching || walletIsLoading}
    >
      <ErrorModal
        open={!!paymentIntentError || !!pageLoadError || !!walletError}
        onClose={() => {
          setPaymentIntentError(null);
          navigate(-1);
        }}
        failReason={paymentIntentError ?? "unknownSystemError"}
      />
      <div className="pt-4 mx-2 flex flex-col">
        {window.ApplePaySession && window.ApplePaySession.canMakePayments ? (
          <div
            id="applepay-button"
            className="apple-pay-button"
            onClick={() => applePay()}
          ></div>
        ) : (
          <div className="basis-1/2">
            <GooglePayButton
              environment={googlePayEnvironment}
              buttonSizeMode="fill"
              buttonType="plain"
              style={{ width: "100%", height: 48 }}
              paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                  {
                    type: "CARD",
                    parameters: {
                      allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                      allowedCardNetworks: [
                        "AMEX",
                        "DISCOVER",
                        "INTERAC",
                        "JCB",
                        "MASTERCARD",
                        "VISA",
                      ],
                      billingAddressRequired: true,
                      billingAddressParameters: { format: "FULL" },
                    },
                    tokenizationSpecification: {
                      type: "PAYMENT_GATEWAY",
                      parameters: {
                        gateway:
                          paymentIntent?.elavonRequestData?.googlePayInfo
                            ?.gateway,
                        gatewayMerchantId: eWalletConfig?.terminalNumber,
                      },
                    },
                  },
                ],
                merchantInfo: {
                  merchantId: eWalletConfig?.convergeMerchantId,
                  merchantName: eWalletConfig?.merchantName,
                  merchantOrigin:
                    paymentIntent?.elavonRequestData?.googlePayInfo
                      ?.merchantOrigin,
                },
                transactionInfo: {
                  totalPriceStatus: "FINAL",
                  totalPriceLabel: "Total",
                  totalPrice: transactionDetails?.amountDue
                    ?.toFixed(2)
                    .replace(/\.00$/, ""),
                  currencyCode: "USD",
                  countryCode: "US",
                },
              }}
              onLoadPaymentData={(paymentRequest) => {
                googlePay(paymentRequest);
              }}
            />
          </div>
        )}
        {
          <div className="my-8">
            <Divider>
              <span className="font-bold">Or pay with card</span>
            </Divider>
          </div>
        }
        <div>
          <div className="m2-4">
            <TextField
              value={cardholderName}
              onChange={(e) => setCardholderName(e.target.value)}
              label="Cardholder Name"
              placeholder="Full name on card"
            ></TextField>
          </div>
          <div
            className="mt-4"
            style={{
              position: "relative",
            }}
          >
            <CreditCardImages />
            <TextField
              value={cardNumber}
              onChange={(e) => handleOnChangeCardNumber(e.target.value)}
              label="Card Number"
              placeholder="1234 1234 1234 1234"
            ></TextField>
          </div>
          <div className="flex mt-4">
            <div className="flex-1 mr-4">
              <TextField
                value={expirationDate}
                onChange={(e) => handleOnChangeExpirationDate(e.target.value)}
                label="Expiration Date"
                placeholder="MM / YY"
              ></TextField>
            </div>
            <div className="flex-1">
              <TextField
                value={securityCode}
                onChange={(e) => handleOnChangeSecurityCode(e.target.value)}
                label="Security Code"
                placeholder="CVC/CVV"
              ></TextField>
            </div>{" "}
          </div>
          <div className="flex mt-4">
            <div className="flex-1">
              <TextField
                value={zipCode}
                onChange={(e) => handleOnChangeZipCode(e.target.value)}
                label="Zip Code"
                placeholder="90210"
                error={zipCodeWarning}
              ></TextField>
            </div>
          </div>
        </div>
        <div>
          <div className="flex border-t border-neutral-light pt-4 mt-8">
            <div className="flex-1 text-left width-50% font-bold text-lg">
              Due Today:
            </div>
            <div className="flex-1 text-right font-bold text-xl">
              <span>
                {" "}
                $
                {transactionDetails?.amountDue?.toFixed(2).replace(/\.00$/, "")}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-4 pt-4 border-t border-neutral-light">
          <Button
            size="large"
            variant="contained"
            onClick={() => handleOnClickBack()}
            color="secondary"
            style={{
              fontWeight: 700,
              width: "30%",
              height: "3rem",
              position: "inline-block",
              marginRight: "1rem",
            }}
            startIcon={<ArrowBack fill="var(--neutral-white-color)" />}
          >
            Back
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={pay}
            color="primary"
            disabled={!paymentInfoValid}
            style={{ fontWeight: 700, width: "64%", height: "3rem" }}
            endIcon={
              <ArrowForward
                fill={
                  !paymentInfoValid
                    ? "rgba(0, 0, 0, 0.26)"
                    : "var(--secondary-color)"
                }
              />
            }
          >
            Pay
          </Button>
        </div>
      </div>
      <ElavonErrorModal
        open={!!showPaymentError}
        onClose={() => setShowPaymentError(false)}
        errorLabel={errorFields?.errorName ?? "Payment Failed"}
        errorDescription={transformElavonError(errorFields)}
      ></ElavonErrorModal>
    </PageLayout>
  );
};

export default Payment;
